<script setup>
import Window from '@/components/Window.vue'
import Table from '@/components/Table.vue'
import Content from '@/views/Layouts/Content.vue'
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
// import { useRoute } from 'vue-router'

import {
  // BriefcaseIcon,
  // CalendarIcon,
  PlusIcon,
  CogIcon,
  // ChevronDownIcon,
  // CurrencyDollarIcon,
  // LinkIcon,
  // LocationMarkerIcon,
  // PencilIcon,
} from '@heroicons/vue/solid/index.js'

const store = useStore()
// const route = useRoute()

// console.log('contacts:route')
// console.log(route.query)

const contacts = computed(() => {
  return store.getters['contact/GET_LIST']
})

const header = ref({
  id: 'ID',
  fullName: 'Имя',
  phone: 'Телефон',
  // 'Комментарий'
})



</script>
<template>
  <div>
    <!-- <pre>{{ contacts }}</pre> -->
    <Content>
      <template #header-controls>
        <!-- <div class="flex gap-3 items-center"> -->
        <span class="sm:ml-3">
          <router-link to="/contacts/create" class="btn btn-primary">
            <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Добавить
          </router-link>
        </span>
        <div class="flex-1">
          <input type="text" placeholder="Фильтр" class="w-full" />
        </div>
        <div>
          <button>
            <CogIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <!-- </div> -->
      </template>
      <template #title>
        <div class="">
          <h3 class="text-2xl leading-7 font-medium text-gray-900">Контакты</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Контакты посетителей театра</p>
        </div>
      </template>
      <template #content>
        <!-- <pre>{{ contacts }}</pre> -->
        <Window>
          <Table :items="contacts" :header="header">
            <template #phone="phoneProps">
              <div class=" ">{{ phoneProps.value }}</div>
            </template>
            <template #controls="props">
              <div class="text-sm">
                <router-link :to="`/contacts/${props.item.id}`">Редактировать</router-link>
              </div>
            </template>
          </Table>
        </Window>
        <!-- <div class="my-3">
          <img src="/tmp/contacts-list.png" alt="" srcset="">
        </div> -->
      </template>
    </Content>
  </div>
</template>